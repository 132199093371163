import styled from 'styled-components'
import React from 'react'
import PropTypes from 'prop-types'
import Loader from 'components/loader'
import SharedStyling from './_shared-styling'
import Themes from './_themes'

const ButtonLoader = () => (
  <Loader size="30px" color="#77777D" dataTestId="loader" />
)

const Button = ({ text, onClick, theme, className, disabled, dataTestId, isSubmitting }) => {
  let callback = onClick;

  if (disabled) {
    callback = () => {}
    dataTestId += "-disabled"
  } else {
    dataTestId += "-enabled"
  }

  return (
    <ButtonView disabled={disabled} theme={theme} data-testid={dataTestId} className={className} isSubmitting={isSubmitting} onClick={callback}>
      {isSubmitting ? <ButtonLoader /> : text }
    </ButtonView>
  )
}

Button.propTypes = {
  onClick: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  dataTestId: PropTypes.string,
  isSubmitting: PropTypes.bool,
  theme: PropTypes.oneOf(Object.values(Themes))
};

Button.defaultProps = {
  dataTestId: "button",
  theme: Themes.primary,
}

const ButtonView = styled.button`
  ${(props) => SharedStyling(props) }
`

export { Button as default, Themes as ButtonThemes };
