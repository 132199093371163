import React from 'react'
import styled from 'styled-components'
import { at, md, lg, xl, xxl, until } from 'models/breakpoints'
import SittingWomanImage from 'images/sitting-woman.svg'
import BackgroundCirclesImage from 'images/repeating-background-circles.svg'
import NumberedCard from 'components/numbered-card'

const HowItWorksSection = () => {
  return (
    <Section>
      <Container>
        <DesktopRow>
          <DesktopColumnLeft>
            <HeadingWrapper>
              <H1>How it Works</H1>
              <p className="body-intro">Connect with other singles in three easy steps.</p>
            </HeadingWrapper>
            <SittingWoman src={SittingWomanImage} alt="Sitting woman using mobile device" />
          </DesktopColumnLeft>
          <DesktopColumnRight>
            <Card>
              <CardContentWrapper>
                <NumberedCard number={1} title="Create a profile" body="We'll walk you through creating a top-notch profile to help you shine."/>
                <NumberedCard number={2} title="Set match criteria" body="Let us know what you’re looking for in a partner."/>
                <NumberedCard number={3} title="Match" body="Match and meet that special someone!"/>
              </CardContentWrapper>
            </Card>
          </DesktopColumnRight>
        </DesktopRow>
        <BackgroundCircles src={BackgroundCirclesImage} />
      </Container>
    </Section>
  );
}


const Section = styled.section`
  background-color: #D6C6FF;
  overflow: hidden;
`

const Container = styled.div.attrs({ className: "container" })`
  position: relative;
  z-index: 0;
`
const DesktopRow = styled.div`
  display: grid;
  align-items: center;
  justify-content: center;
  row-gap: 60px;

  ${at(md,`
    grid-auto-flow: column;
    column-gap: 50px;
  `)}

  ${at(lg, `column-gap: 100px;`)}
  ${at(xl, `column-gap: 150px;`)}
  ${at(xxl, `column-gap: 220px;`)}
`

const HeadingWrapper = styled.div`
  text-align: center;
  margin-bottom: 60px;

  ${at(md, `
    margin-bottom: 100px;
    max-width: 480px;
  `) }
`
const DesktopColumnLeft = styled.div`
`
const DesktopColumnRight = styled.div`
`

const Card = styled.div`
  box-sizing: border-box;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 15%);
  border-radius: 20px;
  background-color: white;
  margin: 0 auto;
  width: 100%;
  padding: 50px 30px;

  ${until(md, `
      max-width: 440px;
  `)}

  ${at(lg, `padding: 70px 30px;`)}
`

const CardContentWrapper = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-gap: 30px;
  align-items: start;
  max-width: 350px;

  ${at(lg, `
    grid-gap: 40px;
    max-width: 420px;
  `)}
`

const H1 = styled.h1`
  margin-bottom: 25px;
  ${at(md, 'margin-bottom: 30px;') }
`

const SittingWoman = styled.img`
    display: block;
    margin: 0 auto;
    width: 180px;

  ${at(md, 'width: 160px') }
  ${at(lg, 'width: 200px') }
`

const BackgroundCircles = styled.img`
  ${until(lg, 'display: none;') }
  position: absolute;
  top: -110px;
  right: 0;
  z-index: -1;
`

export default HowItWorksSection
