import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

// taken from https://loading.io/css/
const InternalLoader = (props) => {
    const { dataTestId, ...other } = props

    return (
      <div {...other } data-testid={props.dataTestId}><div></div><div></div><div></div><div></div></div>
    )
}

const StyledLoaderView = styled(InternalLoader)`
  {
    width: ${props => props.size || "80px"};
    height: ${props => props.size || "80px"};
  }
  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: ${props => props.size || "80px"};
    height: ${props => props.size || "80px"};
    border: 6px solid white;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: ${props => (props.color || "red")} transparent transparent transparent;
  }
  div:nth-child(1) {
    animation-delay: -0.45s;
  }
  div:nth-child(2) {
    animation-delay: -0.3s;
  }
  div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`
const Loader = (props) => (
  <StyledLoaderView {...props } />
)

Loader.propTypes = {
  size: PropTypes.string,
  color: PropTypes.string,
  dataTestId: PropTypes.string,
}


export default Loader
