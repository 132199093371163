import styled from 'styled-components'
import React from 'react'
import PropTypes from 'prop-types'
import SharedStyling from './_shared-styling'
import Themes from './_themes'

const LinkButton = ({ text, theme, className, dataTestId, link }) => (
  <ButtonView href={link} theme={theme} data-testid={dataTestId} className={className}>
    { text }
  </ButtonView>
)

LinkButton.propTypes = {
  text: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  dataTestId: PropTypes.string,
  theme: PropTypes.oneOf(Object.values(Themes))
};

LinkButton.defaultProps = {
  dataTestId: "link-button",
  theme: Themes.primary,
}

const ButtonView = styled.a`
  ${(props) => SharedStyling(props) }
`

export { LinkButton as default };
