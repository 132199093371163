import personIcon from 'images/person-icon.svg'
import heartIcon from 'images/heart-icon.svg'
import shieldIcon from 'images/shield-icon.svg'

const iconsMap = {
  'person': personIcon,
  'heart': heartIcon,
  'shield': shieldIcon
}

class Icons {
  static icon(iconName) {
    const result = iconsMap[iconName];
    if (!result) { console.error("should fail") }
    return result
  }
}

export default Icons
