import Colors from 'models/colors'

const shared = ({ theme, disabled }) => {
  let styles = baseButtonStyling();

  if (disabled) {
    return styles.concat(disabledButtonStyling())
  }

  if (theme === 'primary') {
    return styles.concat(primaryButtonStyling())
  }

  if (theme === 'ghost') {
    return styles.concat(ghostButtonStyling())
  }

  return styles
}

const baseButtonStyling = () => `
  display: block;
  text-decoration: none;
  box-sizing: border-box;
  letter-spacing: 0.5px;
  font-family: 'Helvetica';
  color: ${Colors.white};
  font-size: 16px;
  font-weight: bold;
  padding: 15px;
  border-radius: 22px;
  width: 100%;
  max-width: 340px;
  height: 45px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  &:active {
    transform scale(.99)
  }
`
const ghostButtonStyling = () => `
  @keyframes backgroundTransition {
    from {background-color: transparent;}
    to {
      background-color: #5D36BD;
      color: ${Colors.white};
    }
  }
  &:hover {
    animation-name: backgroundTransition;
    animation-duration: 0.30s;
    background-color: #5D36BD;
    color: ${Colors.white};
  }


  color: #5D36BD;
  border: 1px solid #5D36BD;
  background-color: transparent;
`

const primaryButtonStyling = () => `
  @keyframes backgroundTransition {
    from {background-color: ${Colors.blue500};}
    to {background-color: ${Colors.blue300};}
  }
  &:hover {
    animation-name: backgroundTransition;
    animation-duration: 0.30s;
    background-color: ${Colors.blue300};
  }
  background-color: ${Colors.blue500};
  border: none;
  box-shadow: 0 20px 40px rgba(4, 155, 236, 20%);
`

const disabledButtonStyling = () => `
  background-color: #EDEDFA;
  border: none;
  color: rgba(0,0,0, .5);
`

export default shared
