import axios from "axios"

class FormKeepUrls {
  static stayUpdated = "https://formkeep.com/f/dd826fe1905b.json"
}
class FormKeepCommunicator {
  static async submit(url, data) {
    const postResult = await axios({
      method: "post",
      url,
      data,
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
    })

    if ((postResult.status >= 200) && (postResult.status <= 299)) {
      return { success: true }
    }
  }
}

export { FormKeepUrls, FormKeepCommunicator }
