import Colors from 'models/colors'
import { createGlobalStyle } from 'styled-components'
import { at, md, lg, xl, xxl } from 'models/breakpoints'

const GlobalStyle = createGlobalStyle`
  body { margin: 0; width: 100%; font-family: 'Helvetica'; }

  h1, h2, h3, h4, .body-intro, .body-main, .medium, .caption, .small, p {
    color: ${Colors.black};
    font-family: "Helvetica Neue", "Helvetica", Helvetica, Arial, "Lucida Grande", sans-serif;
    margin-bottom: 0;
    margin-top: 0;
  }

  h1, h2, h3, h4 { font-weight: bold; }

  h1 { font-size: 2.5rem; }
  h2 { font-size: 2.06rem; display: none; }
  h3 { font-size: 1.625rem; }
  h4 { font-size: 1.5rem; }
  .body-intro { font-size: 1.25rem; opacity: 0.8; }
  .body-main { font-size: 1rem; }
  .medium { font-size: 0.938rem; }
  .caption { font-size: 0.875rem; }
  .small { font-size: 0.813rem; }
  .medium, .caption, .small { opacity: 0.8 }

  ${at(lg, `
    h1 { font-size: 3.75rem; }
    h2 { font-size: 50px; }
    h3 { font-size: 40px; }
    h4 { font-size: 30px; }
    .body-intro { font-size: 1.5rem; }
    .body-main { font-size: 1.25rem; }
    .medium { font-size: 1.063rem; }
    .caption { font-size: 0.938rem; }
    .small { font-size: 0.813rem; }
  `)}

  .container {
    padding-left: 16px;
    padding-right: 16px;
    margin: 0 auto;
    max-width: 540px;

    ${at(md, `max-width: 720px;`)}
    ${at(lg, `max-width: 960px;`)}
    ${at(xl, `
      padding-left: 20px;
      padding-right: 20px;
      max-width: 1140px;
    `)}
    ${at(xxl, `max-width: 1320px;`)}
  }

  section {
    padding-top: 50px;
    padding-bottom: 50px;

    ${at(md, `
      padding-top: 100px;
      padding-bottom: 100px;
    `)}
  }
`

export default GlobalStyle
