import React, { useState } from 'react'
import styled from 'styled-components'
import { useForm, Controller } from 'react-hook-form'
import Select from 'components/select'
import PropTypes from 'prop-types'
import { FormKeepCommunicator, FormKeepUrls } from 'network'
import Button from 'components/button'

const StayUpdatedForm = (props) => {
  const { register, handleSubmit, formState, control } = useForm({ mode: 'onTouched' });
  const { errors } = formState
  const [submitting, setSubmitting] = useState(false)

  const onSubmit = data => {
    if (formState.isValid) {
      setSubmitting(true)

      FormKeepCommunicator
        .submit(FormKeepUrls.stayUpdated, data )
        .then(() => props.onSubmit({ success: true }))
        .catch(() => props.onSubmit({ success: false }))
    }
  }

  return (
    <FormWrapper>
      <Form>
        <Label htmlFor="emailAddressInput">Email address</Label>
        <InputAndErrorWrapper>
          <Input id="emailAddressInput" type="email" {
            ...register(
              "email",
              {
                required: true,
                pattern: /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/ // eslint-disable-line
                // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/email#validation
              }
            )
          } placeholder="Email address" />
          { errors.email && <ErrorMessage>That email doesn't look quite right.</ErrorMessage> }
        </InputAndErrorWrapper>

        <Label htmlFor="ageRange">Age range</Label>

        <InputAndErrorWrapper>
          <Controller
            name="ageRange"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Select
                  {...field}
                  inputId="ageRange"
                  placeholder="Age range"
                  isSearchable={false}
                  options={ageRangeOptions}
                  components={{
                    IndicatorSeparator: () => null
                  }}
              />
            )}
          />

          { errors.ageRange && <ErrorMessage>Looks like we're missing an age range.</ErrorMessage> }
        </InputAndErrorWrapper>

        <Label htmlFor="platform">Platform</Label>
        <InputAndErrorWrapper>
          <Controller
            name="platform"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Select
                {...field}
                inputId="platform"
                placeholder="Platform"
                isSearchable={false}
                options={platformOptions}
                components={{
                  IndicatorSeparator: () => null
                }}
              />
            )}
          />
          { errors.platform && <ErrorMessage>Hey there! Are you an iOS or Android user?</ErrorMessage> }
        </InputAndErrorWrapper>
      </Form>
    <Button
      disabled={!formState.isValid || submitting}
      isSubmitting={submitting}
      onClick={handleSubmit(onSubmit)}
      text="Submit"
    />
    </FormWrapper>
  )
}

const ageRangeOptions = [
  { value: "18-29", label: "18 - 29 years" },
  { value: "30-39", label: "30 - 39 years" },
  { value: "40-49", label: "40 - 49 years" },
  { value: "50+", label: "50+ years" }
];

const platformOptions = [
  { value: "ios", label: "iOS" },
  { value: "android", label: "Android" }
]

const FormWrapper = styled.div`
  display: grid;
  grid-auto-flow: row;
  row-gap: 40px;
`;

const Form = styled.form`
  display: grid;
  grid-auto-flow: row;
  row-gap: 20px;
`;

const Label = styled.label`
  display: none;
`

const InputAndErrorWrapper = styled.div`
`;

const ErrorMessage = styled.p.attrs({ className: "caption" })`
  color: #D25858;
  padding-left: 8px;
  font-weight: bold;
  margin-top: 8px;
`;

const Input = styled.input`
  height: 45px;
  border-radius: 22px;
  font-size: 16px;
  width: 100%;
  max-width: 340px;
  display: block;
  padding-left: 16px;
  border: 1px solid rgba(0,0,0, 0.25);
  background-color: white !important;
  &::placeholder {
    color: rgba(0,0,0, 0.7);
  }
  box-sizing: border-box;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 10%);
  &:focus {
    outline: none;
    border-color: #00CFFD;
  }
  -webkit-appearance: none;
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active
  {
   -webkit-box-shadow: 0 0 0 30px white inset !important;
  }
  ::-moz-placeholder {
      opacity: 1;
  }
`

StayUpdatedForm.propTypes = {
  onSubmit: PropTypes.func
}

StayUpdatedForm.defaultProps = {
  onSubmit: () => {}
}

export default StayUpdatedForm
