class Colors {
  static black = "#000";
  static white = "#fff";

  static purple100 = "#EDE5FF";
  static purple200 = "#BEA4FE";
  static purple300 = "#B58BFE";
  static purple400 = "#9972FE";
  static purple500 = "#8352FD";
  static purple600 = "#7E35FD";
  static purple700 = "#7303FC";
  static purple800 = "#6003BC";
  static purple900 = "#49027B";

  static blue100 = "#CDECFE";
  static blue200 = "#7CDDFD";
  static blue300 = "#0EC0FB";
  static blue400 = "#0EA8FB";
  static blue500 = "#049BEC";
  static blue600 = "#0384C9";
  static blue700 = "#0B378E";
  static blue800 = "#0D3773";
  static blue900 = "#0B305B";


  static pink100 = "#F5D1FA";
  static pink200 = "#F1BAF7";
  static pink300 = "#EAA6F2";
  static pink400 = "#E48FEF";
  static pink500 = "#DC7DE8";
  static pink600 = "#C159CF";
  static pink700 = "#9F39AC";
  static pink800 = "#6A2673";
  static pink900 = "#610561";

  static gray100 = "#EBEDF9";
  static gray200 = "#CDD1E4";
  static gray300 = "#B6BBD8";
  static gray400 = "#9399BE";
  static gray500 = "#757CA3";
  static gray600 = "#3C3F5D";
  static gray700 = "#282C4D";
  static gray800 = "#1F2238";
  static gray900 = "#0A0E29";

  // old
  static darkPurple = "#5D36BD";
  static lightTeal = "#CAF2FB";
  static lightBlue = "#60C7FF";
  static neutral = "#EDEDFA";
  static neutralDark = "#1A2151";
  static purple = "#8352FD";
}

export default Colors
