import React from 'react'
import Colors from 'models/colors'
import styled from 'styled-components'
import IconCard from 'components/icon-card'
import { at, until, md, lg, xl} from 'models/breakpoints'
import IllustratedPhoneImage from 'images/phone.svg'

const FeaturesSection = () => {
  return (
    <Section>
      <div className="container">
        <HeadingWrapper>
          <H1>Features</H1>
          <BodyIntro>Here are some of the features we think you're going to love.</BodyIntro>
        </HeadingWrapper>
        <IllustratedPhone src={IllustratedPhoneImage} alt="Illustrated phone" />

        <CardWrapper>
          {cardContent.map((content) => {
            const { title, body, icon } = content;
            return <IconCard key={title} title={title} body={body} icon={icon} />
          })}
        </CardWrapper>
      </div>
    </Section>
  );
}

const cardContent = [
  {
    title: "Large network",
    body: "Our goal is to have the largest network of Pentecostal singles. We’ll be constantly investing in growing our user base, which means more users for you to match with.",
    icon: "person"
  },

  {
    title: "Easy introductions",
    body: "Making the first move can be challenging. We make things easier by matching you first, and then initiating things with an ice breaker.",
    icon: "heart"
  },

  {
    title: "Privacy baked in",
    body: "You cannot be searched by your name. You will only show up if you meet someone’s match criteria. We’ll always proceed with your privacy in mind.",
    icon: "shield"
  },
]

const Section = styled.section`
  background-color: ${Colors.white};
`
const HeadingWrapper = styled.div`
  margin: 0 auto;
  text-align: center;
  margin-bottom: 60px;

  ${at(md, `
    margin-bottom: 100px;
    max-width: 480px;
  `) }
`
const H1 = styled.h1`
  margin-bottom: 25px;
  ${at(md, 'margin-bottom: 30px;') }
`
const BodyIntro = styled.p.attrs({ className: "body-intro" })`
  ${until(lg,`max-width: 340px;`)}
  max-width: 480px;
  margin: 0 auto;
  font-weight: 500;
`
const IllustratedPhone = styled.img`
  display: block;
  margin: 0 auto;
  width: 230px;
  margin-bottom: 60px;
  ${at(md, 'width: 300px;') }
  ${at(lg, 'width: 400px;') }
`
const CardWrapper = styled.div`
  display: grid;
  justify-content: center;
  row-gap: 25px;

  ${at(xl, `
    grid-template-columns: repeat(2, auto);
    column-gap: 30px;
    > :last-child:nth-child(2n - 1) {
      grid-column: span 2;
    }
  `) }
`


export default FeaturesSection
