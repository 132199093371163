import React from 'react'
import Colors from 'models/colors'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Icons from 'models/icons'
import { at, until, sm, md, lg } from 'models/breakpoints'

const IconCard = ({ body, icon, title }) => (
  <IconCardView>
    <Row>
      <Title>{title}</Title>
      <Icon src={Icons.icon(icon)} alt="icon" />
    </Row>
    <Medium>{body}</Medium>
  </IconCardView>
)

IconCard.propTypes = {
  body: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
}

const IconCardView = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border-radius: 20px;
  background-color: #D9F3FB;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 15%);
  opacity: 0.85;
  backdrop-filter: blur(40px);

  margin: 0 auto;

  width: 100%;
  max-width: 343px;
  height: 180px;
  padding: 20px 16px;

  ${at(sm, `
    max-width: 425px;
    height: 212px;
    padding: 40px 35px;
  `)}

  ${at(lg, `
    max-width: 550px;
    height: 275px;
    padding: 50px 35px;
  `)}
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: fex-start;
  align-items: center;
  margin-bottom: 16px;
`

const Title = styled.h4`
  ${until(sm, `font-size: 1.2rem`)}

  margin-right: 20px;
  ${at(md, `margin-right: 25px`)}

  color: ${Colors.blue900};
`

const Icon = styled.img`
  width: 20px;
  ${at(sm, `width: 25px`)}
`
const Medium = styled.p.attrs({ className: "body-main" })`
  line-height: 1.3;

  ${until(sm, `
    font-size: 0.938rem;
    opacity: 0.8;
  `)}

`

export default IconCard;
