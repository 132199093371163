import React from 'react'
import ButtonBase from 'components/button'
import styled from 'styled-components'
import Colors from 'models/colors'
import IllustratedCoupleImage from 'images/illustrated-couple.svg'
import { until, at, md, lg } from 'models/breakpoints'
import { scroller } from 'react-scroll'

const Hero = () => {
  return (
    <Section>
      <div className="container">
        <DesktopRow>
          <HeadingWrapper>
            <H1>Meet Single<br />Apostolics</H1>
            <DesktopBodyIntro>
              <BodyIntroLighter>It’s hard to meet other Pentecostal singles.</BodyIntroLighter> <BodyIntroBr />
              <BodyIntroBold>We’re building an app to fix that.</BodyIntroBold>
            </DesktopBodyIntro>
          </HeadingWrapper>
          <IllustratedCouple src={IllustratedCoupleImage} alt="Couple holding hands" />
          <MobileBodyIntro>
            <BodyIntroLighter>It’s hard to meet other Pentecostal singles.</BodyIntroLighter> <BodyIntroBr />
            <BodyIntroBold>We’re building an app to fix that.</BodyIntroBold>
          </MobileBodyIntro>
        </DesktopRow>
          <Button theme="primary" text="Stay updated" onClick={(e) => {
              scroller.scrollTo('sign-up-form', {
                spy: false,
                duration: 2000,
                delay: 0,
                smooth: 'easeOutQuart'
              });
          } } />
        <Caption>Coming to iOS and Android</Caption>
      </div>
    </Section>
  )
}

const Section = styled.section`
  background-color: ${Colors.blue100};
  ${at(md, `
    padding-bottom 50px;
  `) }
`
const DesktopRow = styled.div`
  display: grid;
  align-items: center;
  justify-content: center;
  row-gap: 40px;

  margin-bottom: 60px;

  ${at(md,`
    grid-auto-flow: column;
    column-gap: 15%;
    margin-bottom: 130px;
  `)}

  ${at(lg,`
    column-gap: 20%;
  `)}
`
const HeadingWrapper = styled.div`
  text-align: center;
  ${at(md, `text-align: left;`) }
`
const H1 = styled.h1`
  color: #082343;
  font-size: 2.9rem;
  margin-bottom: 0;
  ${until(md, `line-height: 1.35;`)}
  ${at(md, 'margin-bottom: 50px; font-size: 3.75rem;') }
  ${at(lg, 'font-size: 5rem;') }
`
const MobileBodyIntro = styled.p.attrs({ className: "body-intro" })`
  color: ${Colors.black};
  text-align: center;
  font-size: 1rem;
  line-height: 1.6;
  max-width: 345px;
  margin: 0 auto;

  ${at(md, `display: none;`) }
`
const DesktopBodyIntro = styled.p.attrs({ className: "body-intro" })`
  color: ${Colors.black};
  ${until(md, `display: none;`)}
  ${at(md, `display: block;`) }
`
const BodyIntroLighter = styled.span`
  color: ${Colors.blue900};
  line-height: 1.7;
`;

const BodyIntroBold = styled.span`
  color: ${Colors.blue900};
  font-weight: bold;
  ${at(md, `opacity: 1;`) }
`;
const BodyIntroBr = styled.br`
  display: none;
  ${at(md, 'display: block;') }
`
const Caption = styled.p.attrs({ className: "caption" })`
  color: ${Colors.black};
  text-align: center;
  opacity: 0.8;
`
const IllustratedCouple = styled.img`
  ${until(md, `margin: 0 auto;`)}
  width: 200px;
  ${at(lg, 'width: 232px') }
`
const Button = styled(ButtonBase)`
  margin: 0 auto;
  margin-bottom: 30px;
  ${at(md, 'margin-bottom: 40px;') }
`
export default Hero
