import React from 'react'
import Colors from 'models/colors'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { at, xs, md, lg } from 'models/breakpoints'

const NumberedCard = ({ number, title, body }) => (
  <NumberedCardView>
    <Column>
      <Circle>
        <Number>{ number }</Number>
      </Circle>
    </Column>
    <Column>
      <TextWrapper>
        <Header><TinyNumber>{ number }</TinyNumber>{ title }</Header>
        <p className="medium">{ body }</p>
      </TextWrapper>
    </Column>
  </NumberedCardView>
)

NumberedCard.propTypes = {
  number: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired
};

const NumberedCardView = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
`

const Circle = styled.div`
  display: none;

  ${at(xs, `display: flex;`)}
  border-radius: 50%;
  width: 42px;
  height: 42px;
  background-color: ${Colors.purple100};
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-right: 30px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 5%);

  ${at(lg, `
    margin-right: 40px;
  `)}
`
const Number = styled.p`
  font-size: 14px;
  ${at(md, `font-size: 18px;`)}
`

const Header = styled.p`
  font-weight: medium;
  font-size: 17px;
  margin-bottom: 10px;
  font-weight: 600;
  color: #2F0B49;

  ${at(md, `
    font-size: 18px;
    margin-bottom: 15px;
  `)}
`

const TinyNumber = styled.span`
  font-size: 15px;
  margin-right: 10px;
  opacity: 0.65;
  ${at(xs, 'display: none !important;')}
`

const Column = styled.div`
`

const TextWrapper = styled.div`
`

export default NumberedCard;
