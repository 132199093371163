import React, { useState } from 'react'
import Colors from 'models/colors'
import styled from 'styled-components'
import StayUpdatedForm from 'components/stay-updated-form'
import PropTypes from 'prop-types'
import { LinkButton } from 'components/button'
import GuyWithPhoneImage from 'images/guy-with-phone.svg'
import GradientBackground from 'images/gradient-background.svg'
import DesktopGradientBackground from 'images/desktop-gradient-background.svg'
import { at, sm, md, lg } from 'models/breakpoints'

const StayUpdatedFormSection = ({ Form }) => {
  const [formState, setFormState] = useState(formStates.fresh);
  const onFormSubmit = (result) => {
    if (result.success) {
      setFormState(formStates.success)
    } else {
      setFormState(formStates.error)
    }
  }

  const Content = getContent(formState)

  return (
    <Background id="sign-up-form">
      <Section>
        <div className="container">
          <Modal>
            <FormAndContentWrapper>
              <Content />
                {(formState === formStates.fresh) &&
                  <GroupWithDisclaimer>
                    <Form onSubmit={onFormSubmit} />
                    <p className="small">You’ll only hear from us if we have something really important to share.</p>
                  </GroupWithDisclaimer>
                }
            </FormAndContentWrapper>
            <ImageBox>
              <GuyWithPhone src={GuyWithPhoneImage} alt="Illustrated man on a phone" />
            </ImageBox>
          </Modal>
        </div>
      </Section>
    </Background>
  )
}

const formStates = {
  fresh: "fresh",
  success: "success",
  error: "error"
}

const getContent = (formState) => {
  switch(formState) {
    case formStates.success:
      return FormSuccessContent
    case formStates.error:
      return FormSuccessContent
    default:
      return FreshContent
  }
}

const FreshContent = () => (
  <HeadingWrapper>
    <h4>Interested? Let us know!</h4>
    <p className="body-main">
      We're currently measuring app interest. If you sign up,
      we'll know you're interested and we'll keep you updated.
    </p>
  </HeadingWrapper>
)

const FormSuccessContent = () => (
  <FormSubmittedContentWrapper>
    <HeadingWrapper>
      <h4>All set</h4>
      <p className="body-main">
        Thanks for subscribing to our updates.
        Feel free to share our page and help spread the word!
        It gives us a leg up in building a great app.
      </p>
    </HeadingWrapper>
    <GroupWithDisclaimer>
      <LinkButton
        link="http://www.facebook.com/sharer/sharer.php?u=https://apostolicsconnect.com"
        text="Share to Facebook"
        theme="ghost"
      />
    </GroupWithDisclaimer>
  </FormSubmittedContentWrapper>
)

const FormErrorContent = () => (
  <FormSubmittedContentWrapper>
    <h4>Something went wrong :(</h4>
    <p className="body-main">Oh no! Something went wrong. Our developers have been made aware of this incident. Try refreshing this page and submitting the form again.</p>
  </FormSubmittedContentWrapper>
)

const Background = styled.div`
  background: rgb(184,114,227);
  background: linear-gradient(90deg, rgba(184,114,227,1) 0%, rgba(101,127,235,1) 47%, rgba(119,204,250,1) 100%);
  background-image: url(${GradientBackground});
  background-repeat: none;
  background-size: cover;
  background-position: center;
  box-sizing: border-box;

  ${at(sm, `background-image: url(${DesktopGradientBackground});`) }
  ${at(lg, `background-attachment: fixed;`) }

  min-height: 100vh;
  display: flex;
  align-items: center;
`
const Section = styled.section`
  backdrop-filter: blur(0px);
  flex: 1;
`
const flexStyling = () => `
  display: flex;
  flex-direction: column;
  gap: 40px;
  justify-content: center;
`
const Modal = styled.div`
  max-width: 440px;
  background-color: ${Colors.white};
  box-shadow: 0 20px 40px rgba(0, 0, 0, 15%);
  box-sizing: border-box;
  border-radius: 22px;
  margin: 0 auto;

  display: grid;
  grid-auto-flow: column;
  grid-template-columns: auto;

  ${at(md, `
    max-width: 1100px;
  `) }

`
const ImageBox = styled.div`
  display: none;

  ${at(md, `
    border-radius: 0 22px 22px 0;
    background-color: ${Colors.neutralDark};
    display: flex;
    justify-content: center;
    align-items: center;
  `) }
`

const GuyWithPhone = styled.img`
  width: 150px;

  ${at(lg, `
    width: 180px;
  `) }
`
const FormAndContentWrapper = styled.div`
  padding: 40px 16px;
  width: 100%;
  box-sizing: border-box;
  margin: 0 auto;
  ${flexStyling()}
  p { line-height: 1.65; }

  max-width: 340px;
  min-height: 600px;

  ${at(sm, `
    padding-left: 0;
    padding-right: 0;
  `) }

  ${at(lg, `
    max-width: 420px;
    min-height: 720px;
  `) }
`
const FormSubmittedContentWrapper = styled.div`
  ${flexStyling()}
`
const GroupWithDisclaimer = styled.div`
  ${flexStyling()}
  gap: 20px;
`

const HeadingWrapper = styled.div`
  h4 { margin-bottom: 12px; }
`

StayUpdatedFormSection.propTypes = {
  Form: PropTypes.elementType
}

StayUpdatedFormSection.defaultProps = {
  Form: StayUpdatedForm
}

export default StayUpdatedFormSection
