class Breakpoints {
  static xxs = 0;
  static xs = 350;
  static sm = 576;
  static md = 768;
  static lg = 992;
  static xl = 1200;
  static xxl = 1400;

  static until = (breakpoint, styling) => `
    @media (max-width: ${breakpoint + .9999 - 1 +  "px"}) {
      ${styling}
    }
  `;

  static at = (breakpoint, styling) => `
    @media (min-width: ${breakpoint + "px"}) {
      ${styling}
    }
  `;
}

const { until, at, xxs, xs, sm, md, lg, xl, xxl } = Breakpoints;
export { Breakpoints as default, until, at, xxs, xs, sm, md, lg, xl, xxl };
