import React from "react"
import GlobalStyles from "components/global-styles"
import Hero from "components/hero"
import HowItWorksSection from "components/how-it-works-section"
import FeaturesSection from "components/features-section"
import FormSection from "components/form-section"
import { Helmet } from "react-helmet"

const IndexPage = () => {
  return (
    <>
      <Helmet>
        <title>Apostolics Connect - Home</title>
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
        <meta name="description" content="Landing page for Apostolics Connect" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Apostolics Connect - Home" />
        <meta property="og:url" content="https://apostolicsconnect.com" />
        <meta property="og:description" content="We're working to make it easy to meet other Apostolic singles." />
        <meta property="og:image" content="https://apostolicsconnect.com/og-image.png" />
        <meta property="og:image:width" content="1400"/>
        <meta property="og:image:height" content="700"/>
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:image" content="https://apostolicsconnect.com/og-image.png"/>
      </Helmet>
      <GlobalStyles />
      <main>
        <title>Home Page</title>
        <Hero />
        <HowItWorksSection />
        <FeaturesSection />
        <FormSection />
      </main>
    </>
  )
}

export default IndexPage
