import React from 'react'
import ReactSelect from 'react-select'
import Colors from 'models/colors'
import styled from 'styled-components'

const Select = React.forwardRef((props, ref) => {
  return (
    <SelectView
      {...props}
      ref={ref}
      styles={{
        control: (styles, { isFocused, isSelected }) => ({
          ...styles,
          borderRadius: '22px',
          paddingLeft: '6px',
          borderColor: (isFocused || isSelected) ? '#00CFFD': 'rgba(0,0,0, 0.25)',
          '&:hover': {
            borderColor: (isFocused || isSelected) ? '#00CFFD': 'rgba(0,0,0, 0.25)'
          },
          boxShadow: '0 2px 5px rgba(0, 0, 0, 10%)',
          height: '45px',
        }),
        container: (styles) => ({
          ...styles,
          borderRadius: '22px',
          boxShadow: 'none',
        }),
        placeholder: (styles) => ({
          ...styles,
          color: 'rgba(0,0,0, 0.7)',
          fontSize: '16px',
        }),
        dropdownIndicator: (styles, { isFocused, isSelected }) => ({
          ...styles,
          color: (isFocused || isSelected) ? '#00CFFD': 'rgba(0,0,0, 0.25)',
          '&:hover': {
            color: (isFocused || isSelected) ? '#00CFFD': 'rgba(0,0,0, 0.25)'
          },
        }),
        menu: (styles) => ({
          ...styles,
          border: '1px solid rgba(0,0,0, 0.25)',
          boxShadow: '0 2px 5px rgba(0, 0, 0, 10%)',
        }),
        option: (styles, { isFocused, isSelected }) => ({
          ...styles,
          color: 'rgba(0,0,0, 0.7)',
          backgroundColor: (isFocused || isSelected) ? Colors.white: Colors.white,
          '&:hover': {
            backgroundColor: (isFocused || isSelected) ? Colors.gray100: Colors.white
          }
        }),
      }}
      components={{
        IndicatorSeparator: () => null
      }}
    />
  )
})

const SelectView = styled(ReactSelect)`
  font-family: 'Helvetica';
  width: 100%;
  max-width: 340px;
`
export default Select
